import React, { useState } from 'react';
import { Header, Modal, Icon, Button, Accordion, Label, Container } from 'semantic-ui-react';
import { styleSheet } from '../../assets/styles/global';
import { AccordionContainer, AccordionSpan, HeaderSpan, SpanUrlHttp } from './styles';

export interface IErrorProps {
  title: string;
  message: string;
  visible: boolean;
  statusHttp: number;
  urlHttp: string;
  onClose(): void;
}

const Error: React.FC<IErrorProps> = ({ message, title, visible, statusHttp, urlHttp, onClose }) => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);

  function handleOpenDropdown() {
    if (activeIndex === 0) {
      setActiveIndex(undefined);
    }
    else {
      setActiveIndex(0);
    }
  }

  return (
    <Modal
      onClose={() => onClose()}
      open={visible}
      size='small'
      style={{ fontFamily: styleSheet.mainFont }}
      closeIcon
    >
      <Header size='huge' icon style={{ background: styleSheet.dangerColor, color: styleSheet.shape }}>
        <Icon name='warning sign' />
      </Header>
      <Modal.Content style={{ fontSize: '1.8rem' }} scrolling>
        <HeaderSpan>
          {title}
        </HeaderSpan>
        <p dangerouslySetInnerHTML={{ __html: message }}></p>
        <Accordion>
          <Accordion.Title
            active={activeIndex === 0}
            index={0}
            onClick={handleOpenDropdown}
          >
            <Icon name='dropdown' color='red' />
            <Label size="huge" color='red' content="Detalhes" />
          </Accordion.Title>
          <Accordion.Content active={activeIndex === 0}>
            <Container>
              <AccordionContainer>
                <AccordionSpan>
                  {statusHttp.toString()}
                </AccordionSpan>
                <SpanUrlHttp>
                  {urlHttp}
                </SpanUrlHttp>
              </AccordionContainer>
            </Container>
          </Accordion.Content>
        </Accordion>
      </Modal.Content>
      <Modal.Actions>
        <Button size="huge" color='red' inverted onClick={() => onClose()}>
          <Icon name='checkmark' />
          OK
        </Button>
      </Modal.Actions>
    </Modal>
  );
}

export default Error;