import styled from "styled-components";
import { styleSheet } from "../../assets/styles/global";

export const HeaderSpan = styled.span`
  font-family: ${({ theme }) => theme.mainFont}; //${ styleSheet.mainFont };
  font-size: 2.4rem;
  font-weight: bold;
`;

export const AccordionContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const SpanUrlHttp = styled.span`
  overflow-wrap: break-word;
  word-wrap: break-word;

  -ms-word-break: break-all;
  word-break: break-all;
  word-break: break-word;
  -ms-hyphens: auto;
  -moz-hyphens: auto;
  -webkit-hyphens: auto;
  hyphens: auto;
`;

export const AccordionSpan = styled.span`
  display: flex;
  align-items: center;
  font-weight: bold;
  font-family: inherit;

  &::after {
    content: "-";
    margin: 0 0.3rem;
  }
`;

