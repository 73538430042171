import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../common/Utils';
import { useSelector } from "react-redux";
import { AuthReducerInitialState } from "../../../reducers/Auth";

interface IBarrahChartProps {
  data: [string | number | object];
  keys?: string[];
  isFullscreen?: boolean;
}

const BarrahChart: FC<IBarrahChartProps> = ({ data, keys, isFullscreen = false }) => {
  const { pwm: configuracoes } = useSelector((state: { auth: AuthReducerInitialState }) => state.auth);
  const utils = new Utils();

  return (
    <Chart
      type="bar"
      width="100%"
      height={isFullscreen ? '500' : '250'} 
      series={data as ApexAxisChartSeries}
      options={
        {
          chart: {
            width: '100%',
            height: isFullscreen ? '500' : '250',
            type: 'bar',
            defaultLocale: 'br',
            locales: utils.localeConfig,
          },
          plotOptions: {
            bar: {
              horizontal: true,
              dataLabels: {
                position: 'top',
              },
            }
          },
          dataLabels: {
            enabled: configuracoes.showhighlighteddash !== undefined ? configuracoes.showhighlighteddash : true,
            offsetX: 0,
            style: {
              fontSize: '9px',
              colors: ['#fff']
            }
          },
          stroke: {
            show: true,
            width: 1,
            colors: ['#fff']
          },
          tooltip: {
            shared: true,
            intersect: false
          },
          xaxis: {
            categories: keys,
          }
        }
      }
    />
  );
}

export default BarrahChart;