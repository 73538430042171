import { createGlobalStyle } from 'styled-components';

export const styleSheet = {
  mainColor: '#56B8F8',
  contrastColor: '#AA4EF6',

  infoColor: '#00C5CD',
  successColor: '#43CD80',
  infoColorDark: '#4682B4',
  dangerColor: '#F2645C',
  warningColor: '#EEC900',
  lighterGrey: '#eeeeee',
  lighterGrey_200: '#cccccc',
  mediumGrey: '#b5b5b5',
  darkerGrey: '#5c5c5c',
  shape: '#FFFFFF',

  defaultFontColor: '#3c3c3c',
  defaultLinkColor: '#1E90FF',

  mainFont: 'Figtree'
}

export const GlobalStyle = createGlobalStyle`
  :root {
    font-size: 60%;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
  }

  html, body, #root {
    height: 100%;
    background: #F7F8FB;
  }

  body {
    font: 400 1.6rem ${styleSheet.mainFont}, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  input,
  select, 
  textarea {
    font: 500 1.3rem ${styleSheet.mainFont}, sans-serif !important;
  }

  .notInPeriod {
    background-color: #ccc;
  }

  svg > g[class^="raphael-group-"] > g > path{
    display: none;
  }



  /* #tableProductRow:focus-within {
    background-color: #e0e0e0 !important;
  } */
  
  .tableProductRow:focus-within {
    background-color: #e0e0e0 !important;
    cursor: pointer;
    color: #000;
  }

  .ui.secondary.pointing.menu .item {
    font-size: 1.2rem;
  }

  .ui.segment.active.tab {
    font-size: 14px;
  }

  .ui[class*="very compact"].table td {
    padding: 0 0.6em !important;
  }

  .dataTableFixed {
    position: sticky !important;
    right: 0 !important;
    background: #fdfdfd !important;
    z-index: 1;
    /* border-right: 1px solid #22242626; */
  } 

  .dataRestfieldTableFixed {
    position: sticky !important;
    right: 0 !important;
    top: 0 !important;
    background: #fdfdfd !important;
    z-index: 1;
  }

  .dataHeaderTableFixed {
    z-index: 3;
    position: sticky !important;
    top: 0 !important;
    background: #f9fafb !important;

    &:hover {
      background: #f9fafb;
    }
    /* border-right: 1px solid #22242626; */
  }

  .dataHeaderTableFixedActions {
    z-index: 3;
    position: sticky !important;
    right: 0 !important;
    top: 0 !important;
    background: #f9fafb !important;

    &:hover {
      background: #f9fafb;
    }
    /* border-right: 1px solid #22242626; */
  }

  .button-pdf-download {
    background: #4fbfe8;
    color: #fff;
    cursor: pointer;
    border: 0;
    padding: 1rem 2rem;
    border-radius: 0.8rem;
  }

  .fc-header-toolbar {
    font-size: 1.2rem;
  }

  .fc-daygrid-day {
    cursor: pointer;
  }

  .dropdown  {
    div.divider {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .ui.disabled.input {
    background: #C9C9C9 !important;
    opacity: 1 !important;
  }

  .ui.disabled.search {
    background-color: #f1f1f1;
    font-weight: 500;
    opacity: 1 !important;
  }

  .ui.form .field :disabled:not(button) {
    background-color: #f1f1f1 !important;
    opacity: 1 !important;
  }

  .blockObs {
    width: 100%;
    resize: none;
    height: 9rem;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }

  .blockDate {
    width: 100%;
    height: 4rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 5px;
  }

  .blockLabel {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 1rem;
  }

  .ui.tabular.menu .item {
    font-family: ${styleSheet.mainFont};
    font-size: 1.1rem;
  }

  .ui.selection.dropdown .menu>.item {
    font-size: 1.3rem;
  }

  .fc-toolbar-chunk .fc-customTitle-button {
    background: transparent !important;
    color: ${styleSheet.darkerGrey} !important;
    font-size: 2.2rem;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    font-family: ${styleSheet.mainFont};
    font-weight: bold;
 
    &:hover {
      background: transparent;
      color: ${styleSheet.darkerGrey};
      border: 0;
      box-shadow: none !important;
    }

    &:focus {
      background: transparent;
      color: ${styleSheet.darkerGrey};
      border: 0;
      box-shadow: none;
    }
  }
  
  .fc-col-header-cell-cushion  {
    color: ${styleSheet.shape} !important;
  }

  .inputFilter input:invalid  {
    border-bottom: 1px solid #f00 !important;
  }

  .ui.dimmer .ui.loadRow.loader:before {
    border-color: rgba(0,0,0,.1);
  }
            
  .ui.dimmer .ui.loadRow.loader:after {
    border-color: #767676 transparent transparent;
  }
  .productQuantity > input::-webkit-outer-spin-button,
  .productQuantity > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .productQuantity > input[type=number] {
    -moz-appearance: textfield;
  }

  .ui.modal>.close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0,0,0,.87);
  }

  .ui.modal .scrolling.content {
    height: 80% !important;
  }

  .ui.modal .scrolling.content {
    height: calc(80vh - 10em) !important;
  }

  .ui.selection.dropdown {
    min-width: 100% !important;
  }

  .inputColor {
    -webkit-appearance: none;
    -mox-appearance: none;
    appearance: none;
    width: 75px;
    height: 75px !important;
    border: none;
    cursor: pointer;
    background-color: transparent
  }

  .inputColor::-webkit-color-swatch {
    border-radius: 50%;
    border: none
  }

  .inputColor::-moz-color-swatch {
    border-radius: 50%;
    border: none
  }

  #modalDashboardContent {
    height: 100% !important;
    max-height: 100%;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  /* #recentProjects {
    div[role="listbox"] {
      max-height: ${window.innerHeight - 550}px;
    }
  } */

  @media (min-width: 700px) {
    :root {
      font-size: 62.5%;
    }
  }
`;

export const GlobalStyleUppercase = createGlobalStyle`
  :root {
    font-size: 60%;
  }

  * {
    margin: 0;
    padding: 0;
    outline: 0;
    box-sizing: border-box;
    text-transform: uppercase !important;
  }

  html, body, #root {
    height: 100%;
    background: #F7F8FB;
  }

  body {
    font: 400 1.6rem ${styleSheet.mainFont}, sans-serif;
    -webkit-font-smoothing: antialiased;
  }

  input,
  select, 
  textarea {
    font: 500 1.3rem ${styleSheet.mainFont}, sans-serif !important;
  }

  .notInPeriod {
    background-color: #ccc;
  }

  svg > g[class^="raphael-group-"] > g > path{
    display: none;
  }

  .dataTableFixed {
    position: sticky !important;
    right: 0 !important;
    top: 0 !important;
    background: #fdfdfd !important;
    z-index: 1;
    /* border-right: 1px solid #22242626; */
  }

  .dataRestfieldTableFixed {
    position: sticky !important;
    right: 0 !important;
    top: 0 !important;
    background: #fdfdfd !important;
    z-index: 1;
  }

  /* #tableProductRow:focus-within {
    background-color: #e0e0e0 !important;
  } */
  
  .tableProductRow:focus-within {
    background-color: #e0e0e0 !important;
    cursor: pointer;
    color: #000;
  }

  .ui.secondary.pointing.menu .item {
    font-size: 1.2rem;
  }

  .ui.segment.active.tab {
    font-size: 14px;
  }

  .ui[class*="very compact"].table td {
    padding: 0 0.6em !important;
  }

  .dataHeaderTableFixed {
    z-index: 3;
    position: sticky !important;
    top: 0 !important;
    background: #f9fafb !important;

    &:hover {
      background: #f9fafb;
    }
    /* border-right: 1px solid #22242626; */
  }



  .button-pdf-download {
    background: #4fbfe8;
    color: #fff;
    cursor: pointer;
    border: 0;
    padding: 1rem 2rem;
    border-radius: 0.8rem;
  }

  .fc-header-toolbar {
    font-size: 1.2rem;
  }

  .fc-daygrid-day {
    cursor: pointer;
  }

  .dropdown  {
    div.divider {
      width: 100%;
      text-overflow: ellipsis;
      white-space: nowrap;
      overflow: hidden;
    }
  }

  .ui.disabled.input {
    background: #C9C9C9 !important;
    opacity: 1 !important;
  }

  .ui.disabled.search {
    background-color: #f1f1f1;
    font-weight: 500;
    opacity: 1 !important;
  }

  .ui.form .field :disabled:not(button) {
    background-color: #f1f1f1 !important;
    opacity: 1 !important;
  }

  .blockObs {
    width: 100%;
    resize: none;
    height: 9rem;
    padding: 5px;
    border-radius: 8px;
    border: 1px solid #ccc;
  }

  .blockDate {
    width: 100%;
    height: 4rem;
    border-radius: 8px;
    border: 1px solid #ccc;
    padding: 5px;
  }

  .blockLabel {
    display: flex;
    flex-direction: column;
    text-align: left;
    margin-bottom: 1rem;
  }

  .ui.tabular.menu .item {
    font-family: ${styleSheet.mainFont};
    font-size: 1.1rem;
  }

  .ui.selection.dropdown .menu>.item {
    font-size: 1.3rem;
  }

  .fc-toolbar-chunk .fc-customTitle-button {
    background: transparent !important;
    color: ${styleSheet.darkerGrey} !important;
    font-size: 2.2rem;
    border: 0 !important;
    outline: 0 !important;
    box-shadow: none !important;
    font-family: ${styleSheet.mainFont};
    font-weight: bold;
 
    &:hover {
      background: transparent;
      color: ${styleSheet.darkerGrey};
      border: 0;
      box-shadow: none !important;
    }

    &:focus {
      background: transparent;
      color: ${styleSheet.darkerGrey};
      border: 0;
      box-shadow: none;
    }
  }
  
  .fc-col-header-cell-cushion  {
    color: ${styleSheet.shape} !important;
  }

  .inputFilter input:invalid  {
    border-bottom: 1px solid #f00 !important;
  }

  .ui.dimmer .ui.loadRow.loader:before {
    border-color: rgba(0,0,0,.1);
  }
            
  .ui.dimmer .ui.loadRow.loader:after {
    border-color: #767676 transparent transparent;
  }
  .productQuantity > input::-webkit-outer-spin-button,
  .productQuantity > input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */
  .productQuantity > input[type=number] {
    -moz-appearance: textfield;
  }

  .ui.modal>.close {
    top: 1.0535rem;
    right: 1rem;
    color: rgba(0,0,0,.87);
  }

  .ui.modal .scrolling.content {
    height: 80% !important;
  }

  .ui.modal .scrolling.content {
    height: calc(80vh - 10em) !important;
  }

  .ui.selection.dropdown {
    min-width: 100% !important;
  }

  .inputColor {
    -webkit-appearance: none;
    -mox-appearance: none;
    appearance: none;
    width: 75px;
    height: 75px !important;
    border: none;
    cursor: pointer;
    background-color: transparent
  }

  .inputColor::-webkit-color-swatch {
    border-radius: 50%;
    border: none
  }

  .inputColor::-moz-color-swatch {
    border-radius: 50%;
    border: none
  }

  #modalDashboardContent {
    height: 100% !important;
    max-height: 100%;
  }

  input[type=number]::-webkit-inner-spin-button, 
  input[type=number]::-webkit-outer-spin-button { 
    -webkit-appearance: none; 
    margin: 0; 
  }

  /* #recentProjects {
    div[role="listbox"] {
      max-height: ${window.innerHeight - 550}px;
    }
  } */

  @media (min-width: 700px) {
    :root {
      font-size: 62.5%;
    }
  }
`;

