import React, { FC } from 'react';
import Chart from "react-apexcharts";
import { Utils } from '../../../common/Utils';

interface IPizzaChartProps {
  data: [string | number | object];
  keys: string[];
  isFullscreen?: boolean;
  filter?: (value: string) => void;
}

const PizzaChart: FC<IPizzaChartProps> = ({ data, keys, isFullscreen = false, filter }) => {
  const utils = new Utils();
  
  return (
    <Chart
      type="pie"
      width="100%"
      height={isFullscreen ? '500' : '250'}
      series={data.map(x => x[1]).slice(1)}
      options={{
        chart: {
          width: '100%',
          height: isFullscreen ? 500 : 250,
          defaultLocale: 'br',
          locales: utils.localeConfig,
          events: {
            dataPointSelection: function(event, chartContext, config) {
              filter(config.w.config.labels[config.dataPointIndex])
            }
          }  
        },
        labels: keys,
        
      }}
      
    />
  );
}

export default PizzaChart;