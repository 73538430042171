import axios, { AxiosInstance } from "axios";

class ProtheusProvider {
  public axiosInstance: AxiosInstance;

  public urls = {
    hml: 'https://protheushml.ethosx.com.br:1141/rest',
    demo: 'https://protheusdemo.ethosx.com.br:1111/rest',
    denver: 'https://hmlprotheusdenver.ethosx.com.br:7776/rest',
    tudoemcarnes: 'https://comercialtudo105999.protheus.cloudtotvs.com.br:4050/rest',
    cadiveu: 'https://brbeauty128013.protheus.cloudtotvs.com.br:8400/rest',
    financeiroEthosx: 'http://54.233.142.167:1290/rest',
    craw: 'http://tnlz4v.hmg.protheus.totvscloud.com.br:26104',
    financeiro: 'https://prdprotheusfinanceiro.ethosx.com.br:7610/rest',
    ethosx: 'https://prdprotheusfinanceiro.ethosx.com.br:7610/rest',
    hmlethosx: 'https://apihml.ethosx.com.br:7612/rest'
  }

  public url = this.urls.hml

  constructor() {
    this.axiosInstance = axios.create({
      baseURL: this.url,
      headers: {
        'Accept-Language': 'pt-BR',
        'Content-Type': 'application/json;charset=UTF-8'
      },
    });

    this.axiosInstance.interceptors.response.use(
      (response) => {
        return response;
      },
      (error) => {           
        if (error.response.status === 400) {     
          if ( error.response.data.msg) {
            error.response.data['msg'] = error.response.data.msg.replace(/\r\n/g, "<br>");
          }

          if ( error.response.data.errorMessage) {
            error.response.data['errorMessage'] = error.response.data.errorMessage.replace(/\r\n/g, "<br>");
          }

          return error.response;
        }   

        return Promise.reject(error);
      }
    );
  }
}

export default new ProtheusProvider().axiosInstance;

