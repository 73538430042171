import React, { FC, useEffect, useState } from "react";

import { CaretUp, CaretDown } from "phosphor-react";

import { Utils } from "../../../common/Utils";
import {
  Icon,
  Menu,
  Table,
  Segment,
  List,
  Dropdown,
  Pagination,
  Input,
  Checkbox,
  Select,
  Button,
  DropdownItemProps,
  Accordion,
} from "semantic-ui-react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import styled from "styled-components";
import { TableColumnText, TableContainer, TableDataText } from "./styles";
import { debounce } from "lodash";
import generateExcel from "zipcelx";
import { useSelector } from "react-redux";
import { PolicieReducerInitialState } from "../../../reducers/Policie";
import PizzaChart from "../PizzaChart";
import BarravChart from "../BarravChart";

interface ITableAndOtherChartProps {
  data: [string | number | object | []];
  keys: string[];
  types: string[];
  subGraph?: string[];
  subGraphType?: string;
  isFullscreen?: boolean;
}

interface ITableFilter {
  id: string;
  value: string;
}

const Styles = styled.div`
  display: block;
  max-width: 100%;
  height: 100%;
  padding: 1rem;

  /* .menu {
    position: absolute;
    top: 0px;
    right: 0px;
  } */

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  .inputSearch {
    width: 100%;
    height: 2.5rem;
    padding-left: 0.5rem;
    border-radius: 4px;
    border: 1px solid #aaa;
  }

  .pagination {
    padding: 0.5rem;
  }

  .buttonChangePage {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #aaa;
    font-size: 1.5rem;
    margin: 0 3px;
    cursor: pointer;
  }

  .pageQuantity {
    width: 10rem;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #aaa;
    font-size: 1.5rem;
  }
`;

const TableAndOtherChart: FC<ITableAndOtherChartProps> = ({
  data,
  keys,
  types,
  isFullscreen = false,
  subGraph,
  subGraphType,
}) => {
  const [showFilter, setShowFilter] = useState(false);
  const [reloadData, setReloadData] = useState(false);
  const [showTableChart, setShowTableChart] = useState(true);
  const [showSecondChart, setShowSecondChart] = useState(true);

  const [selectedRow, setSelectedRow] = useState<number | null>(null)

  const utils = new Utils();
  const { companyPolicies } = useSelector(
    (state: { policie: PolicieReducerInitialState }) => state.policie
  );

  const useThreeDecimalPlaces = companyPolicies.find(
    (policy) =>
      policy.property === "useThreeDecimalPlaces" &&
      policy.policy_value === "true"
  );

  const getBudgetsWithTextSearch = debounce(async () => {
    setReloadData(!reloadData);
  }, 2000);

  function DefaultColumnFilter({
    column: { Header, filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <Input
        id={`input-${Header}`}
        fluid
        defaultValue={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely
        }}
        placeholder={`Pesquisar  ${count} registros...`}
      />
    );
  }

  function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Input
          fluid
          value={filterValue[0] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? parseInt(val, 10) : undefined,
              old[1],
            ]);
            getBudgetsWithTextSearch();
          }}
          placeholder={`Min (${min})`}
          style={{
            width: "45%",
          }}
        />
        {/* to */}
        <Input
          fluid
          value={filterValue[1] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? parseInt(val, 10) : undefined,
            ]);
            getBudgetsWithTextSearch();
          }}
          placeholder={`Max (${max})`}
          style={{
            width: "45%",
          }}
        />
      </div>
    );
  }

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = React.useMemo(
    () =>
      keys.map((item, index) => {
        return {
          Header: item,
          accessor: item,
          Filter:
            types[index] === "C"
              ? DefaultColumnFilter
              : NumberRangeColumnFilter,
          filter: types[index] === "C" ? "" : "between",
          Footer: (info) => {
            if (types[index] === "N") {
              const total = React.useMemo(() => {
                return page.reduce(
                  (sum, row) => parseFloat(row.values[item]) + sum,
                  0
                );
              }, [page.length]);

              return (
                <TableColumnText style={{ fontWeight: "bold" }}>
                  Total:{" "}
                  {utils.convertBrl(total, useThreeDecimalPlaces ? 3 : 2)}
                </TableColumnText>
              );
            } else {
              return "";
            }
          },
        };
      }),
    [reloadData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    setFilter,
    setAllFilters,
    state: { pageIndex, pageSize },
  } = useTable<any>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: data.length > 50 ? 50 : data.length,
      },
      defaultColumn,
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  function getPageSize() {
    const pageSizes: DropdownItemProps[] = [];

    if (data.length > 50) {
      pageSizes.push({ text: "50", value: 50 });
    }
    if (data.length > 100) {
      pageSizes.push({ text: "100", value: 100 });
    }

    if (data.length > 250) {
      pageSizes.push({ text: "250", value: 250 });
    }

    if (data.length > 500) {
      pageSizes.push({ text: "500", value: 500 });
    }

    pageSizes.push({ text: data.length, value: data.length });

    return pageSizes;
  }

  function getHeader(column) {
    if (column.totalHeaderCount && column.totalHeaderCount === 1) {
      return [
        {
          value: column.Header,
          type: "string",
        },
      ];
    } else {
      // const span = [...Array(column.totalHeaderCount - 1)].map(x => ({
      //   value: "",
      //   type: "string"
      // }));
      return [
        {
          value: column.Header,
          type: "string",
        },
        // ...span
      ];
    }
  }

  function getExcel() {
    const config = {
      filename: "download",
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    headerGroups.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push(...(getHeader(column) as never[]));
        });
      }

      dataSet.push(headerRow as never);
    });

    if (page.length > 0) {
      page.forEach((row) => {
        const dataRow = [];

        Object.values(row.cells).forEach(({ value }) =>
          dataRow.push({
            value,
            type: typeof value === "number" ? "number" : "string",
          } as never)
        );

        dataSet.push(dataRow as never);
      });
    } else {
      dataSet.push([
        {
          value: "No data",
          type: "string",
        },
      ] as never);
    }

    return generateExcel(config);
  }

  function generateSubChartData(
    data: any,
    type: "pizza" | "barrav"
  ): [string | number | object] {
    switch (type) {
      case "pizza":
        const dataPizzaCharts = [["", ""]];
        const keyPizzaCharts = [];

        data.forEach((item) => {
          if (!keyPizzaCharts.includes(item.values[subGraph[0]])) {
            keyPizzaCharts.push(item.values[subGraph[0]]);
          }
        });

        keyPizzaCharts.map((key) => {
          let total = 0;
          data.forEach((item) => {
            if (item.values[subGraph[0]] === key) {
              total += parseFloat(item.values[subGraph[1]]);
            }
          }, 0);

          dataPizzaCharts.push([key, total]);
        });
        return dataPizzaCharts as [string | number | object];
      case "barrav":
        const dataBarravCharts = [];
        const keyBarravCharts = [];

        data.forEach((item) => {
          if (!keyBarravCharts.includes(item.values[subGraph[0]])) {
            keyBarravCharts.push(item.values[subGraph[0]]);
          }
        });

        if (!subGraph[1]) {
          const totalSubgraph1 = keyBarravCharts.map((key) => {
            let total = 0;
            data.forEach((item) => {
              if (item.values[subGraph[0]] === key) {
                total += 1;
              }
            }, 0);
  
            return total;
          });
  
          dataBarravCharts.push({
            name: subGraph[0],
            data: totalSubgraph1,
          });
        } else {
          const totalSubgraph1 = keyBarravCharts.map((key) => {
            let total = 0;
            data.forEach((item) => {
              if (item.values[subGraph[0]] === key) {
                total += parseFloat(item.values[subGraph[1]]);
              }
            }, 0);
  
            return total;
          });
  
          dataBarravCharts.push({
            name: subGraph[1],
            data: totalSubgraph1,
          });
        }


        if (subGraph[2]) {
          const totalSubgraph2 = keyBarravCharts.map((key) => {
            let total = 0;
            data.forEach((item) => {
              if (item.values[subGraph[0]] === key) {
                total += parseFloat(item.values[subGraph[2]]);
              }
            }, 0);

            return total;
          });

          dataBarravCharts.push({
            name: subGraph[2],
            data: totalSubgraph2,
          });
        }

        if (subGraph[3]) {
          const totalSubgraph3 = keyBarravCharts.map((key) => {
            let total = 0;
            data.forEach((item) => {
              if (item.values[subGraph[0]] === key) {
                total += parseFloat(item.values[subGraph[3]]);
              }
            }, 0);

            return total;
          });

          dataBarravCharts.push({
            name: subGraph[3],
            data: totalSubgraph3,
          });
        }

        return dataBarravCharts as [string | number | object];
      default:
        return [""];
    }
  }

  function generateSubChartKey(data: any, type: "pizza" | "barrav"): string[] {
    const keyCharts = [];

    switch (type) {
      case "pizza":
        data.forEach((item) => {
          if (!keyCharts.includes(item.values[subGraph[0]])) {
            keyCharts.push(item.values[subGraph[0]]);
          }
        });
        break;
      case "barrav":
        keyCharts.push("");
        data.forEach((item) => {
          if (!keyCharts.includes(item.values[subGraph[0]])) {
            keyCharts.push(item.values[subGraph[0]]);
          }
        });
        break;
      default:
        break;
    }

    return keyCharts;
  }

  useEffect(() => {
    setReloadData(!reloadData);
  }, [pageSize, pageIndex]);

  function handleShowFilters(): void {
    setShowFilter(!showFilter);
    if (!showFilter) {
      setAllFilters([]);
    }
  }

  return (
    <Styles
      style={{
        height: isFullscreen ? `${window.innerHeight - 210}px` : "250px",
      }}
    >
      <Accordion>
        <Accordion.Title
          active={showSecondChart}
          style={{ fontSize: "1.4rem" }}
          onClick={() => setShowSecondChart(!showSecondChart)}
        >
          <Icon name="dropdown" />
          {subGraphType === "pizzalista" ? "Gráfico Pizza" : "Gráfico Barra"}
        </Accordion.Title>
        <Accordion.Content active={showSecondChart}>
          {isFullscreen && (
            <div style={{ height: "300px" }}>
              {subGraphType === "pizzalista" && (
                <PizzaChart
                  data={generateSubChartData(page, "pizza")}
                  keys={generateSubChartKey(page, "pizza")}
                  isFullscreen={false}
                  filter={(value) => {
                    setShowFilter(true);
                    setFilter(subGraph[0], value);
                  }}
                />
              )}
              {subGraphType === "barralista" && (
                <BarravChart
                  data={generateSubChartData(page, "barrav")}
                  keys={generateSubChartKey(page, "barrav")}
                  isFullscreen={false}
                  filter={(value) => {
                    setShowFilter(true);
                    setFilter(subGraph[0], value);
                  }}
                />
              )}
            </div>
          )}
        </Accordion.Content>
        <Accordion.Title
          active={showTableChart}
          style={{ fontSize: "1.4rem" }}
          onClick={() => setShowTableChart(!showTableChart)}
        >
          <Icon name="dropdown" />
          Tabela
        </Accordion.Title>
        <Accordion.Content active={showTableChart}>
          <TableContainer
            style={{
              maxHeight: isFullscreen
                ? `${
                    window.innerHeight -
                    685 +
                    (showTableChart && !showSecondChart ? 300 : 0)
                  }px`
                : "250px",
            }}
          >
            <Table
              style={{
                maxHeight: `${window.innerHeight - 300}px`,
                minHeight: "100px",
              }}
              {...getTableProps()}
              celled
              sortable
              selectable
              color="teal"
            >
              <Table.Header>
                {headerGroups.map((headerGroup) => (
                  <Table.Row
                    style={{ position: "sticky", top: 0,  zIndex: 999999 }}
                    {...headerGroup.getHeaderGroupProps()}
                  >
                    <Table.HeaderCell style={{ background: "#fff" }}>
                      <Menu.Item
                        style={{ textAlign: "center", fontSize: "1.5rem" }}
                      >
                        <Dropdown icon="bars">
                          <Dropdown.Menu 
                            style={{ 
                              position: 'absolute',
                              top: '30px',
                              left: '0px' ,
                            }} 
                            scrolling
                          >
                            <Dropdown.Item as="label" style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: "1.5rem" }}>
                              <Checkbox
                                onClick={() => handleShowFilters()}
                                type="checkbox"
                                checked={showFilter}
                              />{" "}
                              Habilitar Filtros
                            </Dropdown.Item>
                            {allColumns.map((column) => (
                              <Dropdown.Item as="label" style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: "1.5rem" }}>
                                <Checkbox
                                  id={column.id}
                                  type="checkbox"
                                  {...column.getToggleHiddenProps()}
                                />{" "}
                                {column.id}
                              </Dropdown.Item>
                            ))}
                          </Dropdown.Menu>
                        </Dropdown>
                      </Menu.Item>
                    </Table.HeaderCell>
                    {headerGroup.headers.map((column) => (
                      <Table.HeaderCell style={{ background: "#fff" }}>
                        <div
                          {...column.getHeaderProps(
                            column.getSortByToggleProps()
                          )}
                        >
                          <TableColumnText>
                            {column.render("Header")}
                          </TableColumnText>

                          <span style={{ textAlign: "left" }}>
                            {column.isSorted ? (
                              column.isSortedDesc ? (
                                <CaretDown size={15} />
                              ) : (
                                <CaretUp size={15} />
                              )
                            ) : (
                              ""
                            )}
                          </span>
                        </div>

                        {showFilter && (
                          <div>
                            {column.canFilter ? column.render("Filter") : null}
                          </div>
                        )}
                      </Table.HeaderCell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Header>

              <Table.Body {...getTableBodyProps()}>
                {page.map((row, i) => {
                  prepareRow(row);
                  return (
                    <Table.Row 
                      {...row.getRowProps()}
                      active={selectedRow === row.index} 
                      onClick={() => {
                        if (selectedRow !== row.index) {
                          setSelectedRow(row.index)
                        } else {
                          setSelectedRow(null)
                        }
                      }}
                    >
                      <Table.Cell></Table.Cell>
                      {row.cells.map((cell) => {
                        return (
                          <Table.Cell {...cell.getCellProps()}>
                            <TableDataText>{cell.render("Cell")}</TableDataText>
                          </Table.Cell>
                        );
                      })}
                    </Table.Row>
                  );
                })}
              </Table.Body>
              <Table.Footer>
                {footerGroups.map((group) => (
                  <Table.Row
                    style={{
                      position: "sticky",
                      bottom: 0,
                      background: "#fff",
                    }}
                    {...group.getFooterGroupProps()}
                  >
                    <Table.Cell></Table.Cell>
                    {group.headers.map((column) => (
                      <Table.Cell {...column.getFooterProps()}>
                        <TableDataText>{column.render("Footer")}</TableDataText>
                      </Table.Cell>
                    ))}
                  </Table.Row>
                ))}
              </Table.Footer>
            </Table>
          </TableContainer>
          {isFullscreen && (
            <Menu borderless style={{ backgroundColor: "#F9FAFB" }}>
              <Menu.Item>
                <Button onClick={getExcel} color="green">
                  Excel
                </Button>
              </Menu.Item>
              <Menu.Item position="right">
                <Menu.Item>
                  <Select
                    value={pageSize}
                    options={getPageSize()}
                    onChange={(e, data) => {
                      setPageSize(Number(data.value));
                    }}
                    defaultValue={pageSize}
                  />
                </Menu.Item>
                <Menu floated="right">
                  <Pagination
                    defaultActivePage={pageIndex + 1 || 1}
                    activePage={pageIndex + 1}
                    firstItem={null}
                    lastItem={null}
                    pointing
                    secondary
                    totalPages={pageOptions.length}
                    onPageChange={(ev, { activePage }) => {
                      gotoPage(Number(activePage) - 1);
                    }}
                  />
                </Menu>
              </Menu.Item>
            </Menu>
          )}
        </Accordion.Content>
      </Accordion>
    </Styles>
  );
};

export default TableAndOtherChart;
