import React, { FC, useEffect, useState } from "react";
import { CaretUp, CaretDown } from "phosphor-react";
import CurrencyInput from "react-currency-input-field";
import { Utils } from "../../../common/Utils";
import defaultProductImg from '../../../assets/images/default-product.jpeg';

import {
  Menu,
  Table,
  Segment,
  List,
  Dropdown,
  Pagination,
  Input,
  Checkbox,
  Select,
  Button,
  DropdownItemProps,
  Icon,
  Image,
} from "semantic-ui-react";
import {
  useTable,
  useSortBy,
  usePagination,
  useFilters,
  useGlobalFilter,
} from "react-table";
import styled from "styled-components";
import { TableColumnText, TableContainer, TableDataText } from "./styles";
import { debounce } from "lodash";
import generateExcel from "zipcelx";
import { useSelector } from "react-redux";
import { PolicieReducerInitialState } from "../../../reducers/Policie";

interface ITableChartProps {
  data: [string | number | object | []];
  keys: string[];
  types: string[];
  isFullscreen?: boolean;
}

const Styles = styled.div`
  display: block;
  max-width: 100%;
  height: 100%;
  padding: 1rem;

  /* .menu {
    position: absolute;
    top: 0px;
    right: 0px;
  } */

  .tableWrap {
    display: block;
    max-width: 100%;
    overflow-x: scroll;
    overflow-y: hidden;
    border-bottom: 1px solid black;
  }

  .inputSearch {
    width: 100%;
    height: 2.5rem;
    padding-left: 0.5rem;
    border-radius: 4px;
    border: 1px solid #aaa;
  }

  .pagination {
    padding: 0.5rem;
  }

  .buttonChangePage {
    width: 25px;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #aaa;
    font-size: 1.5rem;
    margin: 0 3px;
    cursor: pointer;
  }

  .pageQuantity {
    width: 10rem;
    height: 25px;
    border-radius: 4px;
    border: 1px solid #aaa;
    font-size: 1.5rem;
  }
`;

const TableChart: FC<ITableChartProps> = ({
  data,
  keys,
  types,
  isFullscreen = false,
}) => {
  const { companyPolicies, customizations } = useSelector(
    (state: { policie: PolicieReducerInitialState }) => state.policie
  );

  const [showFilter, setShowFilter] = useState(customizations['enableFiltersOnDashboard'] ? true : false );
  const [reloadData, setReloadData] = useState(false);

  const [selectedRow, setSelectedRow] = useState<number | null>(null)

  const utils = new Utils();
  const useThreeDecimalPlaces = companyPolicies.find(
    (policy) =>
      policy.property === "useThreeDecimalPlaces" &&
      policy.policy_value === "true"
  );

  const getBudgetsWithTextSearch = debounce(async () => {
    setReloadData(!reloadData);
  }, 2000);

  function DefaultColumnFilter({
    column: { filterValue, preFilteredRows, setFilter },
  }) {
    const count = preFilteredRows.length;

    return (
      <Input
        fluid
        value={filterValue || ""}
        onChange={(e) => {
          setFilter(e.target.value || undefined); // Set undefined to remove the filter entirely                   
        }}
        placeholder={`Pesquisar  ${count} registros...`}
      />
    );
  }

  function DisabledColumnFilter({ column: { preFilteredRows } }) {
    const count = preFilteredRows.length;
    return (
      <Input
        fluid
        disabled={true}
        style={{ width: "100%" , cursor: 'not-allowed'}}            
        placeholder={`Pesquisar  ${count} registros...`}
      />
    );
  }


  function between (rows, ids, filterValue)  {
    let [min, max] = filterValue || []       
  
    min = typeof min === 'number' ? min : -Infinity
    max = typeof max === 'number' ? max : Infinity
    
    min = (min);
    max = (max);  

    if (min > max) {
      const temp = min
      min = max
      max = temp
    }

    return rows.filter(row => {
      return ids.some(id => {    
        const rowValue =  utils.removeBRLFormat(row.values[id])          
        return rowValue >= min && rowValue <= max       
      })
    })
  }

  // Desfaz o filtro do tipo monetário  
  between.autoRemove = val =>
  !val || (typeof val[0] !== 'number' && typeof val[1] !== 'number') || val[0] === 0 || val[1] === 0
  
  
  function NumberRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {
      let min = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      let max = preFilteredRows.length ? preFilteredRows[0].values[id] : 0;
      preFilteredRows.forEach((row) => {
        min = Math.min(row.values[id], min);
        max = Math.max(row.values[id], max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >
        <Input
          fluid
          value={filterValue[0] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              val ? parseInt(val, 10) : undefined,
              old[1],
            ]);
            //getBudgetsWithTextSearch();
          }}
          placeholder={`Min (${min})`}
          style={{
            width: "45%",
          }}
        />
        {/* to */}
        <Input
          fluid
          value={filterValue[1] || ""}
          type="number"
          onChange={(e) => {
            const val = e.target.value;
            setFilter((old = []) => [
              old[0],
              val ? parseInt(val, 10) : undefined,
            ]);
            //getBudgetsWithTextSearch();
          }}
          placeholder={`Max (${max})`}
          style={{
            width: "45%",
          }}
        />
      </div>
    );
  }

  function DecimalRangeColumnFilter({
    column: { filterValue = [], preFilteredRows, setFilter, id },
  }) {
    const [min, max] = React.useMemo(() => {      
      let min = preFilteredRows.length ? utils.removeBRLFormat(preFilteredRows[0].values[id]) : 0;
      let max = preFilteredRows.length ? utils.removeBRLFormat(preFilteredRows[0].values[id]) : 0;
      preFilteredRows.forEach((row) => {    
        min = Math.min(utils.removeBRLFormat(row.values[id]), min);
        max = Math.max(utils.removeBRLFormat(row.values[id]), max);
      });
      return [min, max];
    }, [id, preFilteredRows]);

    return (
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
        }}
      >

       <CurrencyInput
          className="currencyInput"               
          placeholder={`Min (${utils.formatFloatAsBRL(min / 100, useThreeDecimalPlaces ? 3 : 2)})`}                 
          allowNegativeValue={true}
          decimalScale={2}        
          decimalsLimit={useThreeDecimalPlaces ? 3 : 2}    
          defaultValue={filterValue[0] || ""}
          type="text"
          onValueChange={(value) => {
            const val = value;                    
            setFilter((old = []) => [
              val ? utils.removeBRLFormat(val) : undefined,
              old[1],
            ]);      
          }}
          style={{
            border: '1px solid #e5e6e9',
            borderRadius:'2px',
            height:'3.5rem',
            marginRight:'5px',
            padding:'0.5rem', 
            width: 'auto',
          }}
        />
         {/* to */}    
       <CurrencyInput
          className="currencyInput"              
          placeholder={`Max (${utils.formatFloatAsBRL(max / 100, useThreeDecimalPlaces ? 3 : 2)})`}
          allowNegativeValue={true}  
          decimalScale={2}        
          decimalsLimit={useThreeDecimalPlaces ? 3 : 2}   
          defaultValue={filterValue[0] || ""}
          type="text"
          onValueChange={(value) => {
            const val = value;                  
            setFilter((old = []) => [
              old[0],
              val ? (utils.removeBRLFormat(val)) : undefined,            
            ]);      
          }}
          style={{
            border: '1px solid #e5e6e9',
            borderRadius:'2px',          
            height:'3.5rem',
            marginRight:'5px',
            padding:'0.5rem', 
            width: 'auto',
          }}
        />       
      </div>
    );
  }

  const filterTypes = React.useMemo(
    () => ({
      text: (rows, id, filterValue) => {
        return rows.filter((row) => {
          const rowValue = row.values[id];
          return rowValue !== undefined
            ? String(rowValue)
                .toLowerCase()
                .startsWith(String(filterValue).toLowerCase())
            : true;
        });
      },
    }),
    []
  );

  const defaultColumn = React.useMemo(
    () => ({
      Filter: DefaultColumnFilter,
    }),
    []
  );

  const columns = React.useMemo(
    () =>
      keys.map((item, index) => {    
        
        if(types[index] === "M") {

          return {
          Header: item,
          accessor: item,          
          Cell: props => props.value,
          filter: between,
          Filter: DecimalRangeColumnFilter,     
          Footer: info => {          
              const total = React.useMemo(
                () =>
                  info.rows.reduce((sum, row) => { 
                    const unformattedFloatValue = utils.removeBRLFormat(row.values[item]);                  
                    const subtotal = (unformattedFloatValue) + sum;          
                    return subtotal;
                  }, 0),
                [info.rows]
              )
              return (
                <TableColumnText style={{ fontWeight: "bold" }}>
                  Total:{" "}
                  {utils.formatFloatAsBRL(total / 100, useThreeDecimalPlaces ? 3 : 2)}
                </TableColumnText>
              );            
            },    
                     
          }
        }

        else if(types[index] === "N") {

          return {
          Header: item,
          accessor: item,          
          Cell: props => props.value,
          filter: 'between',
          Filter: NumberRangeColumnFilter,     
          Footer: info => {          
              const total = React.useMemo(
                () =>
                info.rows.reduce((sum, row) => parseInt(row.values[item]) + sum, 0),
                [info.rows]
              )
              return (
                <TableColumnText style={{ fontWeight: "bold" }}>
                  Total:{" "}
                  {total}
                </TableColumnText>
              );            
            },                    
          }
        } else if (types[index] === "I") {
          return {
            Header: item,
            accessor: item,
            Cell: ({ value }) => {           
              return <Image
                        size="mini"
                        src={value || defaultProductImg}
                        
                        onError={({ currentTarget }) => {
                          currentTarget.onerror = null;
                          currentTarget.src = defaultProductImg;
                        }}
                      />
            },
            Filter: item === "Legenda" ? DisabledColumnFilter : DefaultColumnFilter,                  
         };
        }

        return {
          Header: item,
          accessor: item,
          Cell: ({ value }) => {           
            if(item === "Legenda" && utils.isValidHex(value)) return <Icon name="circle" style={{ color: `${ value }` }} size='large'/>                            
            return value
          },
          Filter: item === "Legenda" ? DisabledColumnFilter : DefaultColumnFilter,                  
       };
      }),
    [reloadData]
  );

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    footerGroups,
    prepareRow,
    page,
    canPreviousPage,
    canNextPage,
    pageOptions,
    pageCount,
    gotoPage,
    nextPage,
    previousPage,
    setPageSize,
    allColumns,
    setAllFilters,
    state: { pageIndex, pageSize },
  } = useTable<any>(
    {
      columns,
      data,
      initialState: {
        pageIndex: 0,
        pageSize: data.length > 50 ? 50 : data.length,
      },
      defaultColumn, // Be sure to pass the defaultColumn option
      filterTypes,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination
  );

  function getPageSize() {
    const pageSizes: DropdownItemProps[] = [];

    if (data.length > 50) {
      pageSizes.push({ text: "50", value: 50 });
    }
    if (data.length > 100) {
      pageSizes.push({ text: "100", value: 100 });
    }

    if (data.length > 250) {
      pageSizes.push({ text: "250", value: 250 });
    }

    if (data.length > 500) {
      pageSizes.push({ text: "500", value: 500 });
    }

    pageSizes.push({ text: data.length, value: data.length });

    return pageSizes;
  }

  function getHeader(column) {
    if (column.totalHeaderCount && column.totalHeaderCount === 1) {
      return [
        {
          value: column.Header,
          type: "string",
        },
      ];
    } else {
      // const span = [...Array(column.totalHeaderCount - 1)].map(x => ({
      //   value: "",
      //   type: "string"
      // }));
      return [
        {
          value: column.Header,
          type: "string",
        },
        // ...span
      ];
    }
  }

  function getExcel() {
    const config = {
      filename: "download",
      sheet: {
        data: [],
      },
    };

    const dataSet = config.sheet.data;

    headerGroups.forEach((headerGroup) => {
      const headerRow = [];
      if (headerGroup.headers) {
        headerGroup.headers.forEach((column) => {
          headerRow.push(...getHeader(column));
        });
      }

      dataSet.push(headerRow);
    });

    if (page.length > 0) {
      page.forEach((row) => {
        const dataRow = [];

        Object.values(row.cells).forEach(({ value }) =>
          dataRow.push({
            value,
            type: typeof value === "number" ? "number" : "string",
          } as never)
        );
        dataSet.push(dataRow);
      });
    } else {
      dataSet.push([
        {
          value: "No data",
          type: "string",
        },
      ]);
    }

    return generateExcel(config);
  }

  useEffect(() => {
    setReloadData(!reloadData);
  }, [pageSize, pageIndex]);

  function handleShowFilters(): void {
    setShowFilter(!showFilter);
    if (!showFilter) {
      setAllFilters([]);
    }
  }

  return (
    <Styles
      style={{
        height: isFullscreen ? `${window.innerHeight - 215}px` : "250px",
      }}
    >
      <TableContainer
        style={{
          maxHeight: isFullscreen ? `${window.innerHeight - 315}px` : "250px",
        }}
      >
        <Table
          style={{ maxHeight: `${window.innerHeight - 300}px`, height: `${page.length <= 7 && '250px'}` }}
          {...getTableProps()}
          celled
          sortable
          selectable
          color="teal"
        >
          <Table.Header>
            {headerGroups.map((headerGroup) => (
              <Table.Row
                style={{ position: "sticky", top: 0,  zIndex: 999999  }}
                {...headerGroup.getHeaderGroupProps()}
              >
                <Table.HeaderCell style={{ background: "#fff" }}>
                  <Menu.Item
                    style={{ textAlign: "center", fontSize: "1.5rem" }}
                  >
                    <Dropdown icon="bars">
                      <Dropdown.Menu 
                        style={{ 
                          position: 'absolute',
                          top: '30px',
                          left: '0px' ,
                        }} 
                        scrolling
                      >
                        <Dropdown.Item as="label" style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: "1.5rem" }}>
                          <Checkbox
                            onClick={() => handleShowFilters()}
                            type="checkbox"
                            checked={showFilter}
                          />{" "}
                          Habilitar Filtros
                        </Dropdown.Item>
                        {allColumns.map((column) => (
                          <Dropdown.Item as="label" style={{ display: 'flex', alignItems: 'center', gap: '10px', fontSize: "1.5rem" }}>
                            <Checkbox
                              id={column.id}
                              type="checkbox"
                              {...column.getToggleHiddenProps()}
                            />{" "}
                            {column.id}
                          </Dropdown.Item>
                        ))}
                      </Dropdown.Menu>
                    </Dropdown>
                  </Menu.Item>
                </Table.HeaderCell>
                {headerGroup.headers.map((column) => (
                  <Table.HeaderCell style={{  maxWidth: '100%' }}>
                    <div
                      {...column.getHeaderProps(column.getSortByToggleProps())}
                    >
                      <TableColumnText>
                        {column.render("Header")}
                      </TableColumnText>

                      <span style={{ textAlign: "left" }}>
                        {column.isSorted ? (
                          column.isSortedDesc ? (
                            <CaretDown size={15} />
                          ) : (
                            <CaretUp size={15} />
                          )
                        ) : (
                          ""
                        )}
                      </span>
                    </div>

                    {showFilter && (
                      <div>
                        {column.canFilter ? column.render("Filter") : null}
                      </div>
                    )}
                  </Table.HeaderCell>
                ))}
              </Table.Row>
            ))}
          </Table.Header>

          <Table.Body {...getTableBodyProps()}>
            {page.map((row ) => {
              prepareRow(row);
              return (
                <Table.Row 
                  {...row.getRowProps()} 
                  active={selectedRow === row.index} 
                  onClick={() => {
                    if (selectedRow !== row.index) {
                      setSelectedRow(row.index)
                    } else {
                      setSelectedRow(null)
                    }
                  }}
                >
                  <Table.Cell></Table.Cell>
                  {row.cells.map((cell, index) => {
                    const isHexa =  utils.isValidHex(row.cells[index].value);                                     
                    return (
                      <Table.Cell {...cell.getCellProps()} textAlign= { isHexa ? 'center' : 'left'} >
                        <TableDataText>{cell.render("Cell")}</TableDataText>
                      </Table.Cell>
                    );
                  })}
                </Table.Row>
              );
            })}
          </Table.Body>
          <Table.Footer>
            {footerGroups.map((group) => (
              <Table.Row
                style={{ position: "sticky", bottom: 0, background: "#fff" }}
                {...group.getFooterGroupProps()}
              >
                <Table.Cell></Table.Cell>
                {group.headers.map((column) => (
                  <Table.Cell {...column.getFooterProps()}>
                    <TableDataText>{column.render("Footer")}</TableDataText>
                  </Table.Cell>
                ))}
              </Table.Row>
            ))}
          </Table.Footer>
        </Table>
      </TableContainer>
      {isFullscreen && (
        <Menu borderless style={{ backgroundColor: "#F9FAFB" }}>
          <Menu.Item>
            <Button onClick={getExcel} color="green">
              Excel
            </Button>
            {/* <Button size="big" style={{ position: 'absolute', top: '1rem', right: '3rem' }} onClick={getExcel} color='green'>Excel</Button> */}
          </Menu.Item>
          <Menu.Item position="right">
            <Menu.Item>
              <Select
                value={pageSize}
                options={getPageSize()}
                onChange={(e, data) => {
                  setPageSize(Number(data.value));
                }}
                defaultValue={pageSize}
              />
            </Menu.Item>
            <Menu floated="right">
              <Pagination
                defaultActivePage={pageIndex + 1 || 1}
                activePage={pageIndex + 1}
                firstItem={null}
                lastItem={null}
                pointing
                secondary
                totalPages={pageOptions.length}
                onPageChange={(ev, { activePage }) => {
                  gotoPage(Number(activePage) - 1);
                }}
              />
            </Menu>
          </Menu.Item>
        </Menu>
      )}
      {/* <div className="pagination">
        <button className='buttonChangePage' onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
          {'<<'}
        </button>{' '}
        <button className='buttonChangePage' onClick={() => previousPage()} disabled={!canPreviousPage}>
          {'<'}
        </button>{' '}
        <button className='buttonChangePage' onClick={() => nextPage()} disabled={!canNextPage}>
          {'>'}
        </button>{' '}
        <button className='buttonChangePage' onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
          {'>>'}
        </button>{' '}
        <span>
          Pagina{' '}
          <strong>
            {pageIndex + 1} de {pageOptions.length}
          </strong>{' '}
        </span>
        <select
          className='pageQuantity'
          value={pageSize}
          onChange={e => {
            setPageSize(Number(e.target.value))
          }}
        >
          {[
            data.length > 25 ? 25 : data.length,
            data.length > 50 ? 50 : data.length,
            data.length > 100 ? 100 : data.length,
            data.length > 250 ? 250 : data.length,
            data.length > 500 ? 500 : data.length,
          ].map(pageSize => (
            <option key={pageSize} value={pageSize}>
              Mostrar {pageSize}
            </option>
          ))}
        </select>
    </div> */}
    </Styles>
  );
};

export default TableChart;
